import http from './http';
import sevenIam from './seven-iam';

const getCards = ({ page, size, search }) => http
  .get(`/admin/customerCards?page=${page}&size=${size}&search=${search}`)
  .then((res) => res.data);

const getCard = (cardId) => http
  .get(`/admin/customerCards/${cardId}`)
  .then((res) => res.data);

const getTasks = () => http
  .get('/admin/tasks')
  .then((res) => res.data);

const getCardTypes = () => http
  .get('/admin/customerCards/cardTypes')
  .then((res) => res.data);

const createCard = (card) => http
  .post('/admin/customerCards', card)
  .then((res) => res.data);

const updateCard = (cardId, card) => http
  .put(`admin/customerCards/${cardId}`, card)
  .then((res) => res.data);

const getTenants = (name) => sevenIam
  .get(`/v1/authorization/identity/platform-accounts?name=${name}`)
  .then((res) => res.data);

export default {
  getCards,
  getCard,
  createCard,
  updateCard,
  getTenants,
  getTasks,
  getCardTypes,
};
