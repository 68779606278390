import mutationTypes from './mutationTypes';

export default {
  [mutationTypes.SET_CARDS](state, cards) {
    state.cards = cards;
  },
  [mutationTypes.SET_TASKS](state, tasks) {
    state.tasks = tasks;
  },
  [mutationTypes.SET_CURRENT_CARD](state, card) {
    state.card = card;
  },
  [mutationTypes.SET_CARD_TYPES](state, cardTypes) {
    state.cardTypes = cardTypes;
  },
  [mutationTypes.SET_TENANTS](state, tenants) {
    state.tenants = tenants;
  },
  [mutationTypes.ADD_NOTIFICATION](state, notification) {
    state.notifications = state.notifications.concat(notification);
  },
  [mutationTypes.SAVE_USER](state, user) {
    state.user = user;
  },
  [mutationTypes.REMOVE_NOTIFICATION](state, notification) {
    state.notifications = state.notifications.filter((n) => n.uuid !== notification.uuid);
  },
  [mutationTypes.SAVE_USER](state, user) {
    state.user = user;
  },
};
