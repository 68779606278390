import Vue from 'vue';
import VueRouter from 'vue-router';
import GatewaySlave from '@nsoft/seven-gravity-gateway/slave';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Home'),
  },
  {
    path: '/create',
    name: 'create-card',
    component: () => import(/* webpackChunkName: "create-card" */ '../pages/CreateCard'),
  },
  {
    path: '/edit/:id',
    name: 'edit-card',
    component: () => import(/* webpackChunkName: "edit-card" */ '../pages/EditCard'),
  },
  {
    path: '*',
    redirect: 'home',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.fullPath === to.fullPath) {
    next();
    return;
  }
  if (GatewaySlave) {
    GatewaySlave().emit({
      action: 'Router.ChangeRoute',
      data: {
        route: `/customer-cards${to.fullPath}`,
      },
    });
  }
  next();
});

export default router;
