export default {
  SET_CARDS: 'SET_CARDS',
  SET_CURRENT_CARD: 'SET_CURRENT_CARDS',
  SET_TASKS: 'SET_TASKS',
  SET_CARD_TYPES: 'SET_CARD_TYPES',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  SAVE_USER: 'SAVE_USER',
  SET_TENANTS: 'SET_TENANTS',
};
