import { v4 as uuidv4 } from 'uuid';
import api from '../api';
import mutationTypes from './mutationTypes';

const loadCards = async ({ commit, dispatch }, { page, size, search }) => {
  try {
    const cards = await api.getCards({ page, size, search });
    commit(mutationTypes.SET_CARDS, cards);
  } catch (err) {
    dispatch('handleError', err);
  }
};

const loadCard = async ({ commit, dispatch }, cardUuid) => {
  try {
    const card = await api.getCard(cardUuid);
    commit(mutationTypes.SET_CURRENT_CARD, card);
  } catch (err) {
    dispatch('handleError', err);
  }
};

const loadCardTypes = async ({ commit, dispatch }) => {
  try {
    const cardTypes = await api.getCardTypes();
    commit(mutationTypes.SET_CARD_TYPES, cardTypes);
  } catch (err) {
    dispatch('handleError', err);
  }
};

const createCard = async ({ dispatch }, card) => {
  try {
    await api.createCard(card);
    dispatch('handleSuccess', 'Card created successfully!');
  } catch (err) {
    dispatch('handleError', err);
  }
};

const updateCard = async ({ dispatch }, [cardId, card]) => {
  try {
    await api.updateCard(cardId, card);
    dispatch('handleSuccess', 'Card updated successfully!');
  } catch (err) {
    dispatch('handleError', err);
  }
};

const loadTasks = async ({ commit, dispatch }) => {
  try {
    const tasks = await api.getTasks();
    commit(mutationTypes.SET_TASKS, tasks);
  } catch (err) {
    dispatch('handleError', err);
  }
};

const loadTenants = async ({ commit, dispatch }, name) => {
  try {
    const tenants = await api.getTenants(name);
    commit(mutationTypes.SET_TENANTS, tenants);
  } catch (err) {
    dispatch('handleError', err);
  }
};

const addNotification = ({ commit, dispatch }, notificationData) => {
  const DEFAULT_NOTIFICATION_TIMEOUT = 5000;
  const notification = {
    ...notificationData,
    uuid: uuidv4(),
    timeout: notificationData.timeout || DEFAULT_NOTIFICATION_TIMEOUT,
  };

  setTimeout(() => {
    dispatch('removeNotification', notification);
  }, notification.timeout);

  commit(mutationTypes.ADD_NOTIFICATION, notification);
};

const removeNotification = ({ commit }, notification) => {
  commit(mutationTypes.REMOVE_NOTIFICATION, notification);
};

const handleSuccess = ({ dispatch }, message) => {
  const notificationData = {
    type: 'success',
    message: message || 'Success!',
  };

  dispatch('addNotification', notificationData);
};

const saveUser = ({ commit }, data) => {
  commit(mutationTypes.SAVE_USER, data);
};

const handleError = ({ dispatch }, error) => {
  const message = error.response && (error.response.data && error.response.data.message);

  const notificationData = {
    type: 'error',
    message: message || 'There was an error!',
  };

  dispatch('addNotification', notificationData);
};

export default {
  loadCards,
  loadCard,
  createCard,
  updateCard,
  loadTasks,
  loadTenants,
  handleError,
  handleSuccess,
  addNotification,
  removeNotification,
  saveUser,
  loadCardTypes,
};
