<template>
  <v-app class="customer-cards-app">
    <v-main>
      <v-snackbar v-for="notification in notifications"
        :value="notification"
        :key="notification.uuid"
        :color="notification.type"
        bottom
      >
      {{ notification.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white"
          text
          v-bind="attrs"
          @click="$store.dispatch('removeNotification', notification)">
          Close
        </v-btn>
      </template>
    </v-snackbar>
      <app-navigation />
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import AppNavigation from './components/AppNavigation';

export default {
  name: 'App',
  components: {
    AppNavigation,
  },
  computed: {
    ...mapGetters(['notifications']),
  },
  methods: {
    getCardTypes() {
      this.$store.dispatch('loadCardTypes');
    },
    getTasks() {
      this.$store.dispatch('loadTasks');
    },
  },
  created() {
    this.getTasks();
    this.getCardTypes();
  },
};
</script>

<style lang="scss">
html {
  overflow-y: auto;
}
</style>
