import state from '../../store/state';

const authInterceptor = (requestConfig) => {
  const userData = state.user.user;
  const transformedConfig = requestConfig;

  transformedConfig.headers.Authorization = userData.auth.token;

  return transformedConfig;
};

// eslint-disable-next-line
export { authInterceptor };
