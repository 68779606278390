import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import packageJson from '../package';
import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import slave from './plugins/seven-gravity-gateway/slave';

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
});

const slaveId = process.env.VUE_APP_INTEGRATION_ID;
const allowedOrigins = process.env.VUE_APP_INTEGRATION_ALLOWED_ORIGINS;

slave.init({ slaveId, allowedOrigins, debug: true }).then((res) => {
  // remove '/customer-cards' from the path
  const path = res.data.route.path.slice(15);
  router.push(path);
  store.dispatch('saveUser', res.data);
  app.$mount('#app');
});

if (process.env.VUE_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENVIRONMENT,
    release: `${packageJson.name}@${packageJson.version}`,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    debug: process.env.VUE_APP_ENVIRONMENT !== 'production',
    tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.2 : 1,
    tracingOptions: {
      trackComponents: true,
    },
    // Vue specific
    logErrors: process.env.VUE_APP_ENVIRONMENT === 'production',
    attachProps: true,
    attachStacktrace: true,
  });
}
